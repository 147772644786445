<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <div class="tip" v-if="qualifications.length > 0">
        {{ $t("qualificationHonor.QH_tipOne") }}
      </div>
      <div v-if="qualifications.length > 0">
        <vue-seamless-scroll
          :data="qualifications"
          :class-option="options"
          class="seamless-warp"
        >
          <ul>
            <li v-for="item in qualifications" :key="item.index">
              <img :src="item.image" alt="" />
            </li>
          </ul>
        </vue-seamless-scroll>
        <!-- <el-carousel
          :interval="4000"
          type="card"
          arrow="never"
          indicator-position="none"
          class="QHoneBanner"
          @change="QHoneChange"
        >
          <el-carousel-item
            v-for="(item, index) in qualifications"
            :key="index"
          >
            <img :src="item.image" alt="" />
            <div class="QHoneName" v-if="QHoneIndex == index">
              {{ item.title }}
            </div>
          </el-carousel-item>
        </el-carousel> -->
      </div>
      <div class="tip" v-if="patent.length > 0">
        {{ $t("qualificationHonor.QH_tipTwo") }}
      </div>
      <div v-if="patent.length > 0">
        <vue-seamless-scroll
          :data="patent"
          :class-option="options"
          class="seamless-warp"
        >
          <ul>
            <li v-for="item in patent" :key="item.index">
              <img :src="item.image" alt="" />
            </li>
          </ul>
        </vue-seamless-scroll>
        <!-- <el-carousel
          :interval="4000"
          type="card"
          arrow="never"
          indicator-position="none"
          class="QHoneBanner"
          @change="patentChange"
        >
          <el-carousel-item v-for="(item, index) in patent" :key="index">
            <img :src="item.image" alt="" />
            <div class="QHoneName" v-if="patentIndex == index">
              {{ item.title }}
            </div>
          </el-carousel-item> 
        </el-carousel>-->
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：关于我们-资质与荣誉
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-12 14:12
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "关于我们",
          title_en: "About us",
          path: "aboutUs",
        },
        {
          title_zh: "资质与荣誉",
          title_en: "Qualification and honor",
          path: "",
        },
      ],

      qualifications: [],
      QCIndex: 0,
      patent: [],
      patentIndex: 0,
    };
  },
  computed: {
    options() {
      return {
        // 滚动速度 , 数值越大滚动越快
        step: 0.8,
        // 滚动的数据条数
        limitMoveNum: 2,
        // 鼠标点击停止滚动, 默认true
        hoverStop: true,
        // 按住鼠标停止滚动, 默认true
        openTouch: true,
        // 滚动方向 , 0 向下 , 1 向上 , 2 向左 , 3 向右
        direction: 2,
        // 单步运动停止的高度(默认是0, 无缝不停止的滚动,direction是0或1)
        // 就是滚多高停一下,再滚多高又停一下,停的时间me就是waitTi
        // 这个高度,可以F12审查元素用箭头放在字上面看li的高度是多少
        singleHeight: 32,
        // 单步运动停止的宽度(默认是0, 无缝不停止的滚动,direction是2或3)
        singleWidth: 0,
        // 单步运动停止的时间 (默认值1000ms)
        waitTime: 0,
        // 开启数据实时监控刷新dom
        openWatch: true,
      };
    },
  },
  mounted() {
    this.getHonorList();
  },
  methods: {
    /**
     * 轮播切换
     * 刘嘉鑫
     * 2022-8-19
     */
    QHoneChange(old) {
      // 通知父组件幻灯片切换
      this.QCIndex = old;
    },
    patentChange(old) {
      this.patentIndex = old;
    },

    /**
     * 资质和荣誉
     * 刘嘉鑫
     * 2022-8-19
     */
    getHonorList() {
      this.$request({
        url: "/Index/honorList",
        data: {},
      }).then((res) => {
        this.qualifications =
          res.honor_list.zizhi == undefined ? [] : res.honor_list.zizhi;
        this.patent =
          res.honor_list.zhuanli == undefined ? [] : res.honor_list.zhuanli;
      });
    },
  },
};
</script>
<style>
.QHoneBanner {
  height: 500px;
}

.QHoneBanner .el-carousel__container {
  height: 100% !important;
}

.QHoneBanner img {
  height: 90% !important;
  width: 100% !important;
}

.QHoneBanner .QHoneName {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-top: 20px;
  text-align: center;
}

.QHoneBanner .el-carousel__mask {
  height: 90% !important;
}
</style>
<style lang='scss' scoped>
.outer {
  background: #1a2a60;
  padding: 0 0 120px;
}

.tip {
  font-size: 30px;
  font-family: FZFengYaSongS-GB;
  font-weight: bold;
  color: #ffffff;
  padding: 60px 310px 80px;
}

.seamless-warp {
  height: 360px;
  overflow: hidden;
  ul {
    display: flex;
    align-items: center;
    margin: 0 !important;
    padding: 0 !important;
    li {
      list-style: none;
      img {
        width: 508px;
        height: 100%;
        margin: 0 80px;
      }
    }
  }
}
</style>